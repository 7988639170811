<template>
  <v-container fill-height fluid>
    <v-row
      align="center"
      justify="center"
      v-if="
        $vuetify.breakpoint.name == 'lg' ||
        $vuetify.breakpoint.name == 'xl' ||
        $vuetify.breakpoint.name == 'md'
      "
    >
      <v-img src="@/assets/gambar/404.png" alt="image" max-width="150"></v-img>
      <v-divider vertical class="mx-5"></v-divider>
      <h2>THE PAGE YOU REQUESTED WAS NOT FOUND</h2>
    </v-row>

    <v-row align="center" justify="center" v-else>
      <v-col cols="12" class="mx-auto">
        <v-row align="center" justify="center">
          <v-img src="@/assets/gambar/404.png" alt="image" max-width="150"></v-img>
        </v-row>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-divider class="my-3"></v-divider>
        <h2>THE PAGE YOU REQUESTED WAS NOT FOUND</h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
}
</script>